import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import queryString from "querystring"
import Img from "gatsby"
import { Accordion, AccordionPanel, Box, Text, Markdown, Paragraph } from "grommet"
import { Add, Subtract } from "grommet-icons"
import { storiesMetadata } from "./data/storiesMetadata"
import ImageSharpImage from "./imageHandling/imageSharpImage"
import { graphql, useStaticQuery } from "gatsby"

/*
 *  This will brighten up block quotes used in markdown through Grommet Markdown options
 *  https://codesandbox.io/s/81p8713zw0?initialpath=/markdown&module=/src/Markdown.js&file=/src/Markdown.js:120-242
 */
const Quote = ({ children, ...rest }) => (
  <Box background="brand">
    <blockquote>{children}</blockquote>
  </Box>
);

/*
 *  The storiesData retrieves markdown for blog posts, filtering on the required active boolean
 *  and sorting by the required (for this directory) index - this orders multiple posts with same tag
 *  Note we are sorting in REVERSE order for the index, basically as order of insertion.
 */
const siteDataQuery = graphql`query {
  storiesData:allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/stories/"}, frontmatter: {active: {eq: true}}}, sort: {fields: frontmatter___index, order: ASC}) {
    edges {
      node {
        id
        frontmatter {
          title
          subtitle
          tag
          active
          index
        }
        rawMarkdownBody
        html
        wordCount {
          words
        }
      }
    }
  }
}
`

const SectionBlog = (sectionProps) => {
  const useMountEffect = (fun) => useEffect(fun, [])

  /*
   *  We are retrieving all the posts in stories folder and will insert in proper accordion panels
   *  They are ordered already by topic index (within a tag)
   */
  const siteData = useStaticQuery(siteDataQuery)
//  console.log('The site data = ' + JSON.stringify(siteData, null, 2))
  const storyMarkdownData = siteData.storiesData.edges;

  // blow up the build if we don't have any entries
  if (siteData === undefined || siteData === null) {
    throw Error('Query problem - failsafe indicating that there is a graphql problem in our thoughts section')
  }
//  console.log('The edges are ' + JSON.stringify(siteData.storiesData.edges, null, 2))
  const [activeTopicTag, setActiveTopicTag] = useState(null)
  /*
   * The accordion is controlled by an index - we'll build this as we enumerate panels
   * We can use the topic index of the actual story metadata to control sort order
   *  The inner one handles multiple posts on same tag / topic - we don't remember value from topic to topic
   */
  const [activeTopicAccordionIndex, setActiveTopicAccordionIndex] = useState(null)
  const [activePostAccordionIndex, setActivePostAccordionIndex] = useState(null)

  // use as a one shot to run before the page loads
  const [initialEntry, setInitialEntry] = useState(true)

  /*
   * We can trust getting the location information from props so long as the function has it
   * ie no need to wait for page loaded
   */
  useEffect(() => {
    if (initialEntry) {
//      console.log("Initializing props on load = " + JSON.stringify(sectionProps, null, 2));

      // now set the section based on search
//      console.log('The search string = ' + sectionProps.props.location.search)
      /*
       *  Because props is buried in our props (should change how we define to avoid), we need to remove leading ?
       *  as the search param may be stringified (ie ?topic would be name instead of topic)
       */
      const searchParameters = queryString.parse(sectionProps.props.location.search.replace(/^(\?)/, ""))
//      console.log('The search parameters = ' + JSON.stringify(searchParameters, null, 2));
//      console.log('The search topic = ' + searchParameters.topic)
      /*
       * The logic below allows initial topic to be null if none specified in URL
       * but otherwise the URL param must be valid to be used
       */
      const theInitialTopic =
        (searchParameters.topic === undefined || storiesMetadata.find(elem => elem.tag === searchParameters.topic)) ?
          searchParameters.topic :
          sectionProps.initialTopicTag
      setActiveTopicTag(theInitialTopic)

      // now update the topic index by finding the defaultStoryIndex for this value
      const matchingTopic = storiesMetadata.find(elem => elem.tag === searchParameters.topic)
      console.log("Matching topic = " + JSON.stringify(matchingTopic))
      setActiveTopicAccordionIndex(
        (theInitialTopic === undefined || theInitialTopic === null) ?
          null :
          matchingTopic.defaultStoryIndex
      )
      setInitialEntry(false)
//      setInitialEntry(false)
    }
//    console.log("Hello initial entry, state = " + initialEntry)
  }, [initialEntry])

  const pageLoaded = () => {
//    console.log("Hello page loaded, initial entry state = " + initialEntry)
  }

  /*
   *  We will keep multiple set to false, so value will always be a single element
   *  whenever a new accordion index is chosen, we need to update the topic
   *  But if we close all, we'll get an empty array so check the length and set null if needed
   */
  const onAccordionActiveIndex = (value) => {
    console.log("The new accordion tab index is " + JSON.stringify(value) + ", type = " + typeof value)
    setActiveTopicAccordionIndex(value.length >= 1 ? value[0] : null)
    const newTopic = value[0] ?
      storiesMetadata.find(elem => elem.defaultStoryIndex === value[0]).tag :
      null
    console.log("The new topic tag is " + JSON.stringify(newTopic))
    setActiveTopicTag(newTopic)
    // finally because we changed to a new index, clear the inner post index
    setActivePostAccordionIndex(undefined)
  }

  /*
   *  Get the active or not icon based on our boolean
   */
  const getIsActiveIcon = (value, color) => {
    const theColor = color ? color : "black"
    // console.log("The active state is " + value)
    const theIcon = value ? <Subtract color={theColor}/> : <Add color={theColor}/>
    return (
      theIcon
    )
  }

  /*
   *  We use an accordion to organize the topics - this defines the header
   *  This defines the OUTER accordion at topic level
   */
  const renderOuterPanelHeader = (title, imageIdentifier, active) => (
    // using alignSelf=stretch will literally stretch the image
    // beware of using align = start and possibly moving out image
    // as implemented don't use align other than stretch
    /*
     *  CSS notes:
     *  1) remember to set a min width or the image will not appear
     *  2) setting justify to between on outer box will stretch the elements horizontalls
     *  3) setting align to center in outer box will center in cross (vertical)
     *  4) Put the image and the tag in an inner box so they stay together
     */
    <Box
      direction="row"
      align="center"
      fill
      justify={"between"}
      background={{ color: active ? sectionProps.topicHeadingInFocusColor : sectionProps.topicHeadingNotInFocusColor }}
      pad="medium" gap="small"
    >
      <Box
        direction={"row-responsive"}
        align={"center"}
        justify={"start"}
      >
        <Box
          width={"xsmall"}
          pad={{ right: "small", bottom: "small" }}
        >
          <ImageSharpImage
            imageIdentifier={imageIdentifier}
          />
        </Box>
        <strong>
          <Text>{title}</Text>
        </strong>
      </Box>
      {getIsActiveIcon(active)}
    </Box>
  )

  const renderTitleAsMarkdown = (title) => {
    return (
      <Markdown>
        {title}
      </Markdown>
    )
  }

  /*
   * This renders the inner or post header that appears when multiple entries are there for a topic
   * Subtitle is optional
   */
  const renderInnerPanelHeader = (title, subtitle, active) => (
    /*
     *  CSS notes:
     *  1) remember to set a min width or the image will not appear
     *  2) setting justify to between on outer box will stretch the elements horizontalls
     *  3) setting align to center in outer box will center in cross (vertical)
     *  4) Put the image and the tag in an inner box so they stay together
     */
    <Box
      direction="row"
      align="center"
      fill
      justify={"between"}
      background={{ color: active ? sectionProps.postHeadingColor : sectionProps.postHeadingColor }}
      pad="medium" gap="small"
    >
      <Box
        direction={"row-responsive"}
        align={"center"}
        justify={"start"}
        border={{side: "left", style: "solid", size: "small", color: "grey"}}
        pad={{left: "small"}}
        margin={{horizontal: "small"}}
      >
        <Box direction={"column"} gap={"small"}>
          <strong>
            <Text>{title}</Text>
          </strong>
          <i>
            <Text>{subtitle}</Text>
          </i>
        </Box>
      </Box>
      {getIsActiveIcon(active)}
    </Box>
  )

  const renderPostListForTopic = (postsForTopic, storyType) => {
//              {renderHtmlChildren(data.node.htmlAst)}
    return postsForTopic.map(function(data, index) {
        console.log('Building post list for topic ' + data.node.frontmatter.tag + ', index = ' + activePostAccordionIndex + ', this post index = ' + data.node.frontmatter.index)
        // if there are more than one for same tag, they will all be returned
        return (
          <AccordionPanel
            header={renderInnerPanelHeader(
              renderTitleAsMarkdown(data.node.frontmatter.title),
              data.node.frontmatter.subtitle ? renderTitleAsMarkdown(data.node.frontmatter.subtitle) : undefined,
              data.node.frontmatter.index === activePostAccordionIndex)}>
            <Box
              fill
//              margin={"small"}
            >
              <Markdown
                components={{ p: (props) => <Paragraph margin={{horizontal: "small"}} {...props} fill/> }}
                options={{overrides: {blockquote: { component: Quote }}}}
              >
                {data.node.html}
              </Markdown>
            </Box>
          </AccordionPanel>
        )
      }
    )
  }

  const onActivePostAccordionIndex = (value) => {
    console.log('The new post index = ' + value)
    setActivePostAccordionIndex(value && value.length > 0 ? value[0] : null)
  }

  const renderChildContent = (storyType) => {
    // this will return a markdown block with blog posts if present or the action call if not
//    console.log('They storyType = ' + JSON.stringify(storyType, null, 2))
//    console.log('The story markdown = ' + JSON.stringify(storyMarkdownData, null, 2))
    const postsForTopic = storyMarkdownData.filter(elem => elem.node.frontmatter.tag === storyType.tag);
    console.log('Posts for topic = ' + JSON.stringify(postsForTopic, null, 2))
    return (
      (postsForTopic && postsForTopic.length > 0) ?
        (<Accordion
          active={activePostAccordionIndex}
          onActive={onActivePostAccordionIndex}
        >
          {renderPostListForTopic(postsForTopic, storyType)}
        </Accordion>) :
        (<Text>{storyType.actionCall}</Text>)
    )
  }

  const renderTopicAccordionPanelChildren = () => {
    /*
     * use the action call if we don't have a blog post available
     */

    return storiesMetadata.filter(elem => elem.active === true).map(function(data, index) {
      return (
        <AccordionPanel
          header={renderOuterPanelHeader(data.tagline, data.imageIdentifier, activeTopicAccordionIndex === data.defaultStoryIndex)}
        >
          <Box background={{ color: sectionProps.topicColor }}>
            {renderChildContent(data)}
          </Box>
        </AccordionPanel>
      )
    })
  }
  useMountEffect(pageLoaded)

  /*
 *  CSS notes for parapgraphs - have found these props to not have an effect, other than the size one
 *  Probably still don't understand how to get them to apply - see the inside of components for Markdown
 *  , "props": {"overflow": "hidden", "white-space": "nowrap", "text-overflow": "..."}
 */
  return (
    <Box
      fill={true}
      direction={"column"}
      background={{ "color": sectionProps.backgroundColor }}
    >
      <Accordion
        activeIndex={activeTopicAccordionIndex}
        onActive={onAccordionActiveIndex}
        children={renderTopicAccordionPanelChildren()}
      >
      </Accordion>
    </Box>
  )
}

SectionBlog.propTypes = {
  backgroundColor: PropTypes.string,
  topicHeadingInFocusColor: PropTypes.string,
  topicHeadingNotInFocusColor: PropTypes.string,
  topicColor: PropTypes.string,
  postHeadingColor: PropTypes.string,
  initialTopicTag: PropTypes.string
}

// we will open page with no default topic on blank url
//   initialTopicTag: storiesMetadata.find(e => e.tag === "innovation-overhaul").tag
SectionBlog.defaultProps = {
  backgroundColor: "light-2",
  topicHeadingNotInFocusColor: "light-3",
  topicHeadingInFocusColor: "neutral-3",
  postHeadingColor: "light-4",
  topicColor: "brand",
  initialTopicTag: null
}


export default SectionBlog