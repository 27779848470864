import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionBlog from "../components/sectionBlog"

const PageBlog = (props) => {
  return (
    <Layout>
      <SEO title="3 Leaps Blog"/>
      <SectionBlog props={props}/>
    </Layout>
  )
}

PageBlog.propTypes = {
  backgroundColor: PropTypes.string
}
PageBlog.defaultProps = {
  backgroundColor: "neutral-1"
}

export default PageBlog
